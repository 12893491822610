<template>
    <main class="c-app-content is-sidebar-tiny">
        <div class="row">
            <div class="col-md-12">
                <g-loading v-if="firstLoading" />
                <div v-else class="card title">
                    <div class="card-body title-filter">
                        <div class="row">
                            <div class="col-lg-12 title-filte-right">
                                <b-tabs class="c-tabs-button mr-3" small pills>
                                    <b-tab v-for="parent in parents" :key="parent.id" :title="parent.name" :active="parent.id == filterFormModel.parent_id" @click='setTab(parent.type, parent.id)'></b-tab>
                                </b-tabs>

                                <dropdown  :model="filterFormModel"  :right-menu="true" type="bordered" class="mr-2" icon="fa fa-calendar-alt" attribute="year" :options="yearOptions" :isFilter="true" @change="load"/>
                                <dropdown  :model="filterFormModel" v-if="filterFormModel.year !== -1 "  :right-menu="true" type="bordered" class="mr-2" icon="fa fa-calendar-alt" attribute="quarter" :options="quarterOptions" :isFilter="true" @change="load"/>
                                <province-select class="mr-2" :model="filterFormModel" attribute="province_id" :filter="{ids: $store.state.client.province_id}" :isFilter="true" :multiple="true" @change="load" />

                                <button type="button" v-if="!firstLoading" class="btn btn-outline-gray btn-sm is-border-1 mr-1 ml-3" title="Xuất dữ liệu" @click='exportFile'>
                                    <b-spinner v-if="listLoading" small></b-spinner>
                                    <i v-else class="fa fa-download"></i> Xuất dữ liệu
                                </button>

                                <config v-if='isPrime() || isUsaid() || isTW()' />
                                <button :disabled='listLoading' class="btn btn-gray btn-sm mr-1" @click="load">
                                    <b-spinner v-if="listLoading" small></b-spinner>
                                    <i v-else class="fa fa-sync-alt" ></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class='card-header c-leader-head text-center col-md-12'>
                            <h3 class='' style="float: none; font-size:1.25rem !important">Số người khuyết tật được hỗ trợ: {{totalNKT}}</h3>
                        </div>
                        <div class="col-md">
                            <div class="row mt-2">
                                <div class="card-body pt-0">
                                    <div class="row" style="justify-content:center !important">
                                        <div class="text-center">
                                            <h5 class="title-filter-title card-title">
                                                Số người khuyết tật được dự án hỗ trợ trực tiếp theo giới tính
                                            </h5>
                                        </div>
                                    </div>
                                    <div v-if="loading" class="mt-3 p-1">
                                        <g-loading />
                                    </div>
                                    <div v-else>
                                        <apexchart height="300"  v-if="chartData1.length > 0" :options="chartOptions1" :series="chartData1"></apexchart>
                                        <div v-else class="text-center "  style="height: 300px">
                                            <div class="text-danger mt-5">Không có số liệu </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="row mt-2">
                                <div class="card-body pt-0">
                                    <div class="row" style="justify-content:center !important">
                                        <div class="title-filte-left">
                                            <h5 class="title-filter-title card-title">
                                                Số người khuyết tật được dự án hỗ trợ trực tiếp theo loại hình can thiệp
                                            </h5>
                                        </div>
                                    </div>
                                    <div v-if="loading" class="mt-3 p-1">
                                        <g-loading />
                                    </div>
                                    <div v-else>
                                        <apexchart height="280" :options="chartOptions2" :series="chartData2"></apexchart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body title-body p-0">
                        <div class="row">
                            <div class="c-leader-table col-md-12">
                                <table class="table mb-0">
                                    <tbody v-for="(field, index) in fields" :key="index">
                                        <tr v-if="field.type === 'group'" class="bg-green-light" >
                                            <th>{{field.name}}</th>
                                            <th style="min-width: 100px;" class="text-center">Kết quả</th>
                                            <th style="min-width: 100px;" class="text-center">Chỉ tiêu</th>
                                            <th style="min-width: 100px;" class="text-center">Tiến độ</th>
                                            <th style="min-width: 100px;" class="text-center"></th>
                                        </tr>
                                        <tr v-else-if="$params.indicator[field.code] && $params.indicator[field.code].detail === false" >
                                            <td class="pl-5">
                                                <a class="text-gray">
                                                    <i class="fa fa-angle-right" ></i>
                                                    <span class="pl-2"><b v-if="field.c">{{field.c}}</b> {{field.name}} <span v-if="field.required" class="text-danger" >(*)</span></span>
                                                </a>
                                            </td>
                                            <td class="text-center">
                                    <b-spinner v-if="listLoading" small></b-spinner>
                                    <span v-else-if="vals[field.code] && vals[field.code] != 0" >{{vals[field.code]}}</span>
                                    </td>
                                    <td class="text-center">
                                    <b-spinner v-if="listLoading" small></b-spinner>
                                    <span v-else-if="kpis[field.code]" >{{kpis[field.code]}}</span>
                                    </td>
                                    <td class="text-center small">
                                    <b-spinner v-if="listLoading" small></b-spinner>
                                    <span class="text-secondary" v-else-if="!kpis[field.code] || !vals[field.code] " ></span>
                                    <span class="text-warning" v-else >{{(vals[field.code].replace('%','') / kpis[field.code].replace('%','') * 100).toFixed(2).replace(/\.0+$/,'')}} %</span>
                                    <!--<span class="text-success" v-else >Đạt</span>-->
                                    </td>
                                    <td></td>
                                    </tr>
                                    <tr v-else >
                                        <td class="pl-5">
                                            <a :href="`#/manager/dashboard/${field.code}`" class="text-gray">
                                                <i class="fa fa-angle-right" ></i>
                                                <span class="pl-2"><b v-if="field.c">{{field.c}}</b> {{field.name}} <span v-if="field.required" class="text-danger" >(*)</span></span>
                                            </a>
                                        </td>
                                        <td class="text-center">
                                    <b-spinner v-if="listLoading" small></b-spinner>
                                    <span v-else-if="vals[field.code] && vals[field.code] != 0" >{{vals[field.code]}}</span>
                                    </td>
                                    <td class="text-center">
                                    <b-spinner v-if="listLoading" small></b-spinner>
                                    <span v-else-if="kpis[field.code]" >{{kpis[field.code]}}</span>
                                    </td>
                                    <td class="text-center small">
                                    <b-spinner v-if="listLoading" small></b-spinner>
                                    <span class="text-secondary" v-else-if="!kpis[field.code] || !vals[field.code] " ></span>
                                    <span class="text-warning" v-else>{{(vals[field.code].replace('%','') / kpis[field.code].replace('%','') * 100).toFixed(2).replace(/\.0+$/,'')}} %</span>
                                    <!--<span class="text-success" v-else >Đạt</span>-->
                                    </td>
                                    <td>
                                        <a :href="`#/manager/dashboard/${field.code}`">Chi tiết</a>
                                    </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script>
    import VueApexCharts from 'vue-apexcharts';
    import moment from 'moment';
    import role from '@/mixins/Role';
    import Dropdown from '@/components/Dropdown';
    import Config from './dashboard/Config';
    import ProvinceSelect from '@/components/ProvinceSelect';
    import {saveAs} from 'file-saver';
    import Excel from 'exceljs';

    export default {
        mixins: [role],
        components: {Dropdown, Config, ProvinceSelect, apexchart: VueApexCharts},
        data: function () {
            return {
                firstLoading: true,
                listLoading: false,
                loading: true,
                service: '/dashboard',
                totalNKT: 0,
                config: null,
                fields: [],
                parents: [],
                kpis: {},
                vals: {},
                filterFormModel: {
                    parent_id: null,
                    type: null,
                    quarter: -1,
                    year: -1,
                    province_id: null
                },
                yearOptions: [
                    {value: null, text: "Chọn năm"},
                ],
                quarterOptions: [
                    {value: -1, text: "Chọn quý"},
                    {value: 1, text: "Quý 1"},
                    {value: 2, text: "Quý 2"},
                    {value: 3, text: "Quý 3"},
                    {value: 4, text: "Quý 4"},
                ],
                items1: [],
                chartOptions1: {chart: {type: 'pie'}},
                chartData1: [],
                items2: [],
                chartOptions2: {chart: {type: 'bar'}},
                chartData2: [],
                items3: [],
            };
        },
        methods: {
            setTab(tab, parent_id) {
                this.filterFormModel.type = tab;
                this.filterFormModel.parent_id = parent_id;
                this.load();
            },
            load() {
                this.listLoading = true;
                this.yearOptions = [{value: -1, text: `Toàn dự án`}];
                let currentYear = moment().format("YYYY");
                for (var i = currentYear; i >= 2015; i--) {
                    this.yearOptions.push({value: i, text: `Năm ${i}`});
                }
                if (this.filterFormModel.year == -1) {
                    this.filterFormModel.quarter = -1;
                }
                let filter = Object.assign({}, this.filterFormModel);
                filter.province_id = filter.province_id && filter.province_id.length ? filter.province_id.join(",") : null;

                this.$service.get(`${this.service}/index`, {params: filter}).then(response => {
                    if (response.data) {
                        this.config = response.data.config;
                        this.kpis = response.data.kpis;
                        for (let indicator in this.kpis) {
                            if (indicator == 'indicator02' || indicator == 'indicator04' || indicator == 'indicator06' || indicator == 'indicator08' || indicator == 'indicator21' || indicator == 'indicator11') {
                                this.kpis[indicator] = Number(this.kpis[indicator]).toFixed(2).replace(/\.0+$/, '') + '%';
                            } else {
                                this.kpis[indicator] = Number(this.kpis[indicator]).toFixed(2).replace(/\.0+$/, '');
                            }
                        }
                        this.parents = response.data.parents;
                        let indicators = [];

                        if (this.filterFormModel.parent_id == null) {
                            this.filterFormModel.parent_id = this.parents[0].id;
                            this.filterFormModel.type = this.parents[0].type;
                        }
                        this.fields = [];
                        if (this.filterFormModel.parent_id == this.$store.state.client.id && response.data.fields) {
                            this.fields = response.data.fields.fields;
                            indicators = this.fields.filter(f => f.type === "indicator").map(f => f.code);
                        } else {
                            let config = this.config.find(c => c.type === response.data.type);
                            if (config) {
                                this.fields = config.fields;
                                indicators = this.fields.filter(f => f.type === "indicator").map(f => f.code);
                            }
                        }
                        this.fields = this.fields.filter(f => (f.type == 'indicator' && (!this.$store.state.client.indicators || !this.$store.state.client.indicators.length || this.$store.state.client.indicators.includes(f.code))) || f.type !== 'indicator');
                        this.fields = this.fields.filter((f, index) => {
                            if (f.type === 'group') {
                                if (!this.fields[index + 1] || (this.fields[index + 1] && this.fields[index + 1].type === 'group')) {
                                    return false;
                                }
                            }
                            return true;
                        });

                        let vals = {};
                        for (let indicator of indicators) {
                            vals[indicator] = '';
                            let key = (indicator && indicator[0].toUpperCase() + indicator.slice(1)) || indicator;
                            if (this[`count${key}`]) {
                                this[`count${key}`]();
                            }
                        }
                        this.vals = vals;
                    }
                }).finally(() => {
                    this.firstLoading = false;
                    this.listLoading = false;
                });
                this.loadChartSumary01();
                this.loadChartSumary02();
            },
            exportFile: async function () {
                this.loading = true;
                let workbook = new Excel.Workbook();
                let sheet = workbook.addWorksheet('chi so100');

                let cols = [];
                cols.push({header: "Mã", key: "code", width: 20});
                cols.push({header: "Chỉ số", key: "name", width: 150});
                cols.push({header: "Kết quả", key: "result", width: 20});
                cols.push({header: "Chỉ tiêu", key: "target", width: 20});
                cols.push({header: "Tiến độ", key: "kpi", width: 20});
                sheet.columns = cols;
                sheet.getRow(1).hidden = true;

                for (let field of this.fields) {
                    if (field.type === 'group') {
                        sheet.addRow({
                            name: field.name,
                            result: "Kết quả",
                            target: "Chỉ tiêu",
                            kpi: "Tiến độ",
                        });
                        sheet.getRow(sheet.rowCount).fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: 'd0f9e3'
                            }
                        };
                    } else {
                        let row = {
                            code: field.c,
                            name: field.name,
                            result: this.vals[field.code],
                            target: this.kpis[field.code],
                            kpi: "",
                        };
                        if (this.kpis[field.code] && this.vals[field.code]) {
                            row.kpi = (this.vals[field.code].replace('%', '') / this.kpis[field.code].replace('%', '') * 100).toFixed(2).replace(/\.0+$/, '');
                        }
                        sheet.addRow(row);
                    }


                }
                workbook.xlsx.writeBuffer({base64: true}).then(data => {
                    this.loading = false;
                    let fileName = 'bao_cao_chi_so_' + moment().format('HHmm_DDMMYYYY');
                    saveAs(new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
                            `${fileName}.xlsx`);
                });
            },
            countIndicator(indicator) {
                let filter = Object.assign({}, this.filterFormModel);
                filter.province_id = filter.province_id && filter.province_id.length ? filter.province_id.join(",") : null;

                this.listLoading = true;
                this.$service.get(`${this.service}/${indicator}`, {params: filter}).then(response => {
                    if (response.data) {
                        if (indicator == 'indicator02' || indicator == 'indicator04' || indicator == 'indicator06' || indicator == 'indicator08' || indicator == 'indicator21' || indicator == 'indicator11' || indicator == 'indicator29') {
                            this.vals[indicator] = Number(response.data).toFixed(2).replace(/\.0+$/, '') + '%';
                        } else {
                            this.vals[indicator] = Number(response.data).toFixed(2).replace(/\.0+$/, '');
                        }
                    }
                }).finally(() => {
                    this.listLoading = false;
                });
            },
            countIndicator01() {
                this.countIndicator("indicator01");
            },
            countIndicator02() {
                this.countIndicator("indicator02");
            },
            countIndicator03() {
                this.countIndicator("indicator03");
            },
            countIndicator04() {
                this.countIndicator("indicator04");
            },
            countIndicator05() {
                this.countIndicator("indicator05");
            },
            countIndicator06() {
                this.countIndicator("indicator06");
            },
            countIndicator07() {
                this.countIndicator("indicator07");
            },
            countIndicator08() {
                this.countIndicator("indicator08");
            },
            countIndicator09() {
                this.countIndicator("indicator09");
            },
            countIndicator10() {
                this.countIndicator("indicator10");
            },
            countIndicator11() {
                this.countIndicator("indicator11");
            },
            countIndicator12() {
                this.countIndicator("indicator12");
            },
            countIndicator13() {
                this.countIndicator("indicator13");
            },
            countIndicator14() {
                this.countIndicator("indicator14");
            },
            countIndicator15() {
                this.countIndicator("indicator15");
            },
            countIndicator16() {
                this.countIndicator("indicator16");
            },
            countIndicator17() {
                this.countIndicator("indicator17");
            },
            countIndicator18() {
                this.countIndicator("indicator18");
            },
            countIndicator19() {
                this.countIndicator("indicator19");
            },
            countIndicator20() {
                this.countIndicator("indicator20");
            },
            countIndicator21() {
                this.countIndicator("indicator21");
            },
            countIndicator22() {
                this.countIndicator("indicator22");
            },
            countIndicator23() {
                this.countIndicator("indicator23");
            },
            countIndicator24() {
                this.countIndicator("indicator24");
            },
            countIndicator25() {
                this.countIndicator("indicator25");
            },
            countIndicator26() {
                this.countIndicator("indicator26");
            },
            countIndicator27() {
                this.countIndicator("indicator27");
            },
            countIndicator28() {
                this.countIndicator("indicator28");
            },
            countIndicator29() {
                this.countIndicator("indicator29");
            },
            loadChartSumary01() {
                let filter = Object.assign({}, this.filterFormModel);
                filter.province_id = filter.province_id && filter.province_id.length ? filter.province_id.join(",") : null;

                this.loading = true;
                this.$service.get(`${this.service}/indicator-sumary01`, {
                    params: filter
                }).then(response => {
                    this.items1 = response.data.data;
                    this.totalNKT = response.data.totalNKT;
                    this.tableData = [];
                    this.chartData1 = [];
                    this.chartOptions1 = {
                        grid: {
                            padding: {
                                top: 20,
                            }
                        },
                        plotOptions: {
                            pie: {
                                dataLabels: {
                                    offset: -10
                                }
                            }
                        },
                        labels: this.items1.map(item => item.label),
                        chart: {
                            toolbar: {
                                show: true,
                                offsetX: 0,
                                offsetY: 0,
                                tools: {
                                    download: true,
                                    selection: true,
                                    zoom: true,
                                    zoomin: true,
                                    zoomout: true,
                                    pan: true,
                                    reset: true | '<img src="/static/icons/reset.png" width="20">',
                                    customIcons: []
                                },
                                export: {
                                    csv: {
                                        filename: undefined,
                                        columnDelimiter: ',',
                                        headerCategory: 'category',
                                        headerValue: 'value',
                                        dateFormatter(timestamp) {
                                            return new Date(timestamp).toDateString()
                                        }
                                    },
                                    svg: {
                                        filename: 'csip_indicatorSumary01_' + moment().format('HHmm_DDMMYYYY'),
                                    },
                                    png: {
                                        filename: 'csip_indicatorSumary01_' + moment().format('HHmm_DDMMYYYY'),
                                    }
                                },
                                autoSelected: 'zoom'
                            },
                            zoom: {enabled: false},
                            type: 'pie'
                        },
                        legend: {
                            position: 'bottom'
                        },
                        dataLabels: {
                            enabled: true,
                            formatter: function (val) {
                                return ((val.toString().length > 5) ? val.toFixed(2) : val) + '%';
                            }
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return val + '%';
                                }
                            }
                        },

                    };
                    this.chartData1 = this.items1.map(item => item.total ? item.total : 0);
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    }
                }).finally(() => {
                    this.loading = false;
                    this.firstLoading = false;
                });
            },
            loadChartSumary02() {
                let filter = Object.assign({}, this.filterFormModel);
                filter.province_id = filter.province_id && filter.province_id.length ? filter.province_id.join(",") : null;

                this.loading = true;
                this.$service.get(`${this.service}/indicator-sumary02`, {
                    params: filter
                }).then(response => {
                    this.items2 = response.data.data;
                    this.tableData = [];
                    this.chartData2 = [];
                    this.chartOptions2 = {
                        labels: this.items2.map(item => item.label),
                        chart: {
                            toolbar: {
                                show: true,
                                offsetX: 0,
                                offsetY: 0,
                                tools: {
                                    download: true,
                                    selection: true,
                                    zoom: true,
                                    zoomin: true,
                                    zoomout: true,
                                    pan: true,
                                    reset: true | '<img src="/static/icons/reset.png" width="20">',
                                    customIcons: []
                                },
                                export: {
                                    csv: {
                                        filename: undefined,
                                        columnDelimiter: ',',
                                        headerCategory: 'category',
                                        headerValue: 'value',
                                        dateFormatter(timestamp) {
                                            return new Date(timestamp).toDateString()
                                        }
                                    },
                                    svg: {
                                        filename: 'csip_indicatorSumary02_' + moment().format('HHmm_DDMMYYYY'),
                                    },
                                    png: {
                                        filename: 'csip_indicatorSumary02_' + moment().format('HHmm_DDMMYYYY'),
                                    }
                                },
                                autoSelected: 'zoom'
                            },
                            zoom: {enabled: false},
                            type: 'bar'
                        },
                        plotOptions: {
                            bar: {
                                borderRadius: 4,
                                horizontal: true,
                            }
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return val;
                                }
                            }
                        },
                    };
                    this.chartData2.push({
                        name: 'Số lượng',
                        data: this.items2.map(it => it['c'] ? it['c'] : 0)
                    });
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    }
                }).finally(() => {
                    this.loading = false;
                    this.firstLoading = false;
                });
            },
        },
        mounted: function () {
            this.$bus.$on("dashboard", this.load);
            this.load();
            this.loadChartSumary01();
            this.loadChartSumary02();
        },
        beforeDestroy: function () {
            this.$bus.$off("dashboard");
        },
    }
</script>