<template>
    <button class="btn btn-outline-gray btn-sm is-border-1 mr-2" @click="open">
        <b-spinner v-if="updateLoading" small></b-spinner>
        <i v-else class="fa fa-cogs icon-left"></i> Cài đặt

        <b-modal v-model="showForm" size='xl' :title="`${formTitle}`" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-4'>
                    <draggable id="first" data-source="juju" :list="indicators" class="list-group" draggable=".item" group="a"  style="min-height: 500px;">
                        <div class="list-group-item item" v-for="element in indicators" :key="element.code" >
                            <b>{{element.code}}</b>. {{ element.name }} <b v-if="element.c">{{element.c}}</b> <span v-if="element.required" class="text-danger" >(*)</span>
                        </div>
                    </draggable>
                </div>
                <div class='col-8'>
                    <draggable id="first" data-source="juju" :list="formModel.fields" class="list-group" draggable=".item" group="a"  style="min-height: 500px;">
                        <div class="list-group-item item row" v-for="element in formModel.fields" :key="element.code" >
                            <div class='col-2 mt-2'><b :class="[element.type === 'indicator' ? 'ml-4' : '']" >{{element.code}}</b></div>
                            <div class='col-8'>
                                <b-form-input v-model="element.name" type="text" class="form-control" />
                            </div>
                            <div class='col-2'>
                                <b v-if="element.c">{{element.c}}</b> <span v-if="element.required" class="text-danger" >(*)</span>
                            </div>
                        </div>
                        <div slot="header" class="btn-group list-group-item" role="group" aria-label="Basic example">
                            <button class="btn btn-sm btn-success" @click="addGroup">
                                <i class='fa fa-plus'></i>
                                Thêm nhóm
                            </button>
                        </div>
                    </draggable>
                </div>
            </div>

            <template v-slot:modal-footer>
                <b-button :disabled="saveLoading" variant="danger" class="float-right" @click="reset">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    Về mặc định
                </b-button><!-- comment -->

                <b-button variant="secondary" class="float-right" @click="showForm = false">Đóng</b-button>

                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="save">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save"></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
    </button>
</template>

<script>
    import draggable from 'vuedraggable';
    import crud from '@/mixins/CRUD';
    export default {
        mixins: [crud],
        components: {draggable},
        data() {
            return {
                defaultFormModel: {
                    type: null,
                    fields: [],
                },
                items: [],
                indicators: [],
                client: null,
            }
        },
        methods: {
            reset: function (evt) {
                if (evt) {
                    evt.preventDefault();
                }
                this.formModel.fields = [];
                return this.save();
            },
            save: function (evt) {
                if (evt) {
                    evt.preventDefault();
                }
                this.saveLoading = true;
                let method = 'put';
                let url = '/site/client-update';
                this.client.dashboard = {...this.formModel};
                return this.$service({url: url, method: method, data: this.client}).then(response => {
                    this.showForm = false;
                    this.$bus.$emit("dashboard");
                    if (response.data) {
                        this.$swal({
                            title: "Thành công",
                            text: this.formModel.id ? 'Chỉnh sửa thành công' : 'Thêm mới thành công',
                            icon: 'success',
                            toast: true,
                            position: 'bottom-end',
                            timer: 5000,
                        });
                    } else {
                        this.$swal({
                            title: "Có lỗi",
                            text: this.formModel.id ? 'Chỉnh sửa thất bại' : 'Thêm mới thất bại',
                            icon: 'error',
                            toast: true,
                            position: 'bottom-end',
                            timer: 5000,
                        });
                    }
                    return response.data;
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                        this.showForm = false;
                    } else {
                        this.$swal({
                            text: "Vui lòng kiểm tra lại thông tin",
                            icon: 'warning',
                            toast: true,
                            position: 'bottom-end',
                            timer: 3000,
                        });
                        this.formErrors = error.response.data;
                    }
                }).finally(() => {
                    this.saveLoading = false;
                });
            },
            addGroup: function () {
                this.formModel.fields.push({
                    type: "group",
                    code: 'group_' + (this.formModel.fields.length + 1),
                    name: "Nhóm ",
                });
            },
            async open() {
                this.updateLoading = true;
                let resp = await this.$service.get('/site/profile');
                this.client = resp.data.client;
                this.formModel = {};
                if (this.client.dashboard) {
                    this.formModel = Object.assign({}, this.client.dashboard);
                } else {
                    this.formModel = Object.assign({}, this.defaultFormModel);
                    this.formModel.fields = [];
                }
                this.formErrors = [];
                this.showForm = true;
                this.indicators = [];
                for (let code in this.$params.indicator) {
                    if (this.$params.indicator[code].indicator !== undefined && this.$params.indicator[code].indicator === false) {
                        continue;
                    }
                    //Nếu có cấu hình chỉ số, thì sẽ bắt theo chỉ số
                    if (this.client.indicators && !this.client.indicators.includes(code)) {
                        continue;
                    }
                    let field = this.formModel.fields.find(f => f.code === code);
                    if (!field) {
                        let indicator = {...this.$params.indicator[code]};
                        indicator.c = `${indicator.code}`;
                        indicator.code = code;
                        indicator.type = "indicator";
                        this.indicators.push(indicator);
                    }
                }
                this.updateLoading = false;
                this.showForm = true;
            }
        }
    }
</script>